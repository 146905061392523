import React, { Fragment } from "react";
import PageTitle from "../../components/PageTitle";
import Navbar5 from "../../components/Navbar5";
import Breadcrumbs from "../../components/Breadcrumbs";
import ContactPageSec from "../../components/ContactPageSec";
import Footer from "../../components/Footer";
import FtLogo from "../../images/logo2.png";

const ContactPage = () => {
  return (
    <Fragment>
      <PageTitle title="Contact | Seven Solver" />
      <Navbar5 />
      <Breadcrumbs pageTitle={"Contact"} pageSub={"Contact"} />
      <ContactPageSec />
      <Footer Ftclass={"wpo-footer-area3"} ftLogo={FtLogo} />
    </Fragment>
  );
};
export default ContactPage;
