import React, { Fragment } from "react";
import PageTitle from "../../components/PageTitle";
import Navbar5 from "../../components/Navbar5";
import Breadcrumbs from "../../components/Breadcrumbs";
import BlogList from "../../components/BlogList";
import Footer from "../../components/Footer";
import FtLogo from "../../images/logo2.png";

const BlogPage = () => {
  return (
    <Fragment>
      <PageTitle title="Blogs | Seven Solver" />
      <Navbar5 />
      <Breadcrumbs pageTitle={"Blogs"} pageSub={"Blogs"} />
      <BlogList />
      <Footer Ftclass={"wpo-footer-area3"} ftLogo={FtLogo} />
    </Fragment>
  );
};
export default BlogPage;
