import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop";
import Scrollbar from "../../components/scrollbar";
import Routes from "../__Routes";
import "../../css/style.css";
import "../../css/responsive.css";

function App() {
  return (
    <Router>
      <div className="App" id="scrool">
        <ScrollToTop />
        <Switch>
          <Routes />
        </Switch>
        <Scrollbar />
      </div>
    </Router>
  );
}

export default App;
