import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import profolio1 from "../../images/protfolio/p1.jpg";
import profolio2 from "../../images/protfolio/p2.jpg";
import profolio3 from "../../images/protfolio/p3.jpg";
import profolio4 from "../../images/protfolio/p4.jpg";
import profolio5 from "../../images/protfolio/p5.jpg";
import profolio6 from "../../images/protfolio/p6.jpg";
import profolio7 from "../../images/protfolio/p7.jpg";

const PorfolioSection3 = (props) => {
  // const [porfolio, setPorfolio] = useState([
  //   {
  //     image: profolio7,
  //     name: "Travelling App",
  //     description: "(This application is all about travel information)",
  //   },
  //   {
  //     image: profolio5,
  //     name: "Movie DB",
  //     description:
  //       "This is the movies information application which shows theater, kids, top movies etc.",
  //   },
  // ]);

  return (
    <div className="wpo-protfolio-area-5 section-padding">
      <div className="container">
        <div className="col-12">
          <div className="section-title text-center">
            <h2>Portfolio</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="wpo-protfolio-item">
              <div className="row">
                <div className="col col-12">
                  <div className="protfolio-wrapper">
                    <div className="wpo-protfolio-single">
                      <div className="wpo-protfolio-img">
                        <img src={profolio1} alt="" />
                      </div>
                      <div className="wpo-protfolio-text">
                        <h2>Management app</h2>
                        <span>
                          This is college management application where user can
                          manage their books, fee, and events etc stuff.
                        </span>
                        {/* <Link to="/protfolioGrid2">View Work</Link> */}
                      </div>
                      <div className="thumb-text">
                        <span>01</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-12">
                  <div className="protfolio-rwrapper">
                    <div className="wpo-protfolio-single">
                      <div className="wpo-protfolio-img">
                        <img src={profolio2} alt="" />
                      </div>
                      <div className="wpo-protfolio-text">
                        <h2>Ecommerce app</h2>
                        <span>
                          This is e-commerce application in which your can
                          select a product with different kind of filters such
                          as by price, by ratting, by quality etc.
                        </span>
                        {/* <Link to="/protfolioGrid2">View Work</Link> */}
                      </div>
                      <div className="thumb-text">
                        <span>02</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-12">
                  <div className="protfolio-rwrapper">
                    <div className="wpo-protfolio-single">
                      <div className="wpo-protfolio-img">
                        <img src={profolio3} alt="" />
                      </div>
                      <div className="wpo-protfolio-text">
                        <h2>Learning Kids</h2>
                        <span>
                          This application is for kids learning app which helps
                          kid to learn Animal names, Numbers ,Alphabet etc with
                          voice.
                        </span>
                        {/* <Link to="/protfolioGrid2">View Work</Link> */}
                      </div>
                      <div className="thumb-text">
                        <span>03</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-12">
                  <div className="protfolio-rwrapper">
                    <div className="wpo-protfolio-single">
                      <div className="wpo-protfolio-img">
                        <img src={profolio4} alt="" />
                      </div>
                      <div className="wpo-protfolio-text">
                        <h2>Ecommerce-app</h2>
                        <span>
                          This is e-commerce application UI/UX where user can
                          select product, and checkout by cash or Card.
                        </span>
                        {/* <Link to="/protfolioGrid2">View Work</Link> */}
                      </div>
                      <div className="thumb-text">
                        <span>04</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-12">
                  <div className="protfolio-rwrapper">
                    <div className="wpo-protfolio-single">
                      <div className="wpo-protfolio-img">
                        <img src={profolio5} alt="" />
                      </div>
                      <div className="wpo-protfolio-text">
                        <h2>Movie DB</h2>
                        <span>
                          This is the movies information application which shows
                          theater, kids, top movies etc.
                        </span>
                        {/* <Link to="/protfolioGrid2">View Work</Link> */}
                      </div>
                      <div className="thumb-text">
                        <span>05</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-12">
                  <div className="protfolio-rwrapper">
                    <div className="wpo-protfolio-single">
                      <div className="wpo-protfolio-img">
                        <img src={profolio6} alt="" />
                      </div>
                      <div className="wpo-protfolio-text">
                        <h2>Iron App</h2>
                        <span>
                          This is the Patient information app which gives
                          information to the user according to his health.
                        </span>
                        {/* <Link to="/protfolioGrid2">View Work</Link> */}
                      </div>
                      <div className="thumb-text">
                        <span>06</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-12">
                  <div className="protfolio-rwrapper">
                    <div className="wpo-protfolio-single">
                      <div className="wpo-protfolio-img">
                        <img src={profolio7} alt="" />
                      </div>
                      <div className="wpo-protfolio-text">
                        <h2>Travelling App</h2>
                        <span>
                          (This application is all about travel information)
                        </span>
                        {/* <Link to="/protfolioGrid2">View Work</Link> */}
                      </div>
                      <div className="thumb-text">
                        <span>07</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="protfolio-btn">
                <Link to="/protfolioGrid2">See More Work...</Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PorfolioSection3;
