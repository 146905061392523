import React, { useState, useEffect } from "react";
import firebase from "../../config/firebase";
import { ToastContainer, toast } from "react-toastify";
import emailjs from "emailjs-com";

const ContactForm = () => {
  const contactField = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };
  const [values, setValues] = useState(contactField);
  const [errors, setErrors] = useState(contactField);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleValidate(values);
  }, [values]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (handleValidate(values)) {
      setLoading(true);
      emailjs
        .sendForm(
          "service_h64edwq",
          "template_zau3w0i",
          e.target,
          "user_wR9bNvty5D258hicymZAj"
        )
        .then(
          (result) => {
            console.log("email=>>", result);
          },
          (error) => {
            console.log("error=>>", error);
          }
        );
      console.log("target", e.target);
      console.log("values", values);
      const contactRef = firebase.database().ref("Contacts");
      contactRef.push(values);
      toast("Thank you for contacting us.", {
        position: "bottom-left",
        autoClose: 5000,
      });
      setTimeout(() => {
        setValues(contactField);
        setSubmitted(false);
        setLoading(false);
      }, 5000);
    }
  };

  const handleValidate = (values) => {
    let errors = {};
    let isValid = true;
    if (!values["name"]) {
      isValid = false;
      errors["name"] = "Please enter your name.";
    }
    if (typeof values["email"] !== "undefined") {
      let pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(values["email"])) {
        isValid = false;
        errors["email"] = "Please enter valid email.";
      }
    }
    if (!values["email"]) {
      isValid = false;
      errors["email"] = "Please enter your email.";
    }
    if (!values["subject"]) {
      isValid = false;
      errors["subject"] = "Please enter your subject.";
    }
    if (typeof values["message"] !== "undefined") {
      if (
        typeof values["message"] !== "undefined" &&
        values["message"].length <= 30
      ) {
        isValid = false;
        errors["message"] = "Message should be at least 30 characters.";
      }
    }
    if (!values["message"]) {
      isValid = false;
      errors["message"] = "Please enter your message.";
    }
    setErrors(errors);
    return isValid;
  };

  return (
    <form onSubmit={handleSubmit} className="form">
      <ToastContainer />
      <div className="row">
        <div className="col-md-12">
          <div className="form-field">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={values.name}
              onChange={handleChange}
            />
            <p>{submitted && errors.name}</p>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-field">
            <input
              type="text"
              name="email"
              placeholder="Email"
              value={values.email}
              onChange={handleChange}
            />
            <p>{submitted && errors.email}</p>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-field">
            <input
              type="text"
              name="subject"
              placeholder="Subject"
              value={values.subject}
              onChange={handleChange}
            />
            <p>{submitted && errors.subject}</p>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-field mt-2">
            <textarea
              name="message"
              placeholder="Message"
              value={values.message}
              onChange={handleChange}
            ></textarea>
            <p>{submitted && errors.message}</p>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-submit">
            <button type="submit" className="theme-btn" disabled={loading}>
              Send Message
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
