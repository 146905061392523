import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import Navbar5 from "../../components/Navbar5";
import Breadcrumbs from "../../components/Breadcrumbs";
import Footer from "../../components/Footer";
import ProjectSingle from "../../components/ProjectSingle";
import FtLogo from "../../images/logo2.png";

const CoursesDetails = () => {
  const { name } = useParams();

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  return (
    <Fragment>
      <Navbar5 />
      <Breadcrumbs pageTitle={capitalize(name)} pageSub={"Course Details"} />
      <ProjectSingle _id={name} />
      <Footer Ftclass={"wpo-footer-area3"} ftLogo={FtLogo} />
    </Fragment>
  );
};
export default CoursesDetails;
