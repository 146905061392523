import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";

import html from "../../images/icon/html.png";
import angular from "../../images/icon/angular.png";
import react from "../../images/icon/react.png";
import reactnative from "../../images/icon/reactnative.png";
import android from "../../images/icon/android.png";
import ios from "../../images/icon/ios.png";
import cordova from "../../images/icon/cordova.png";
import nodejs from "../../images/icon/nodejs.png";

const ServiceSection2 = (props) => {
  const [courses, setCourses] = useState([
    {
      id: "html-css-js",
      icon: html,
      name: "HTML/CSS/JS",
      description: "",
    },
    {
      id: "angular",
      icon: angular,
      name: "Angular",
      description: "",
    },
    {
      id: "react",
      icon: react,
      name: "React",
      description: "",
    },
    {
      id: "reactnative",
      icon: reactnative,
      name: "ReactNative",
      description: "",
    },
    {
      id: "",
      icon: android,
      name: "Android",
      description: "",
    },
    {
      id: "",
      icon: ios,
      name: "Ios",
      description: "",
    },
    {
      id: "ionic-cordova",
      icon: cordova,
      name: "Ionic/Cordova",
      description: "",
    },
    {
      id: "",
      icon: nodejs,
      name: "Node js",
      description: "",
    },
  ]);

  return (
    <div className="work-area section-padding">
      <div className="container">
        <div className="col-12">
          <div className="section-title-2 text-center">
            <h2>This is what we guide</h2>
          </div>
        </div>
        <div className="row">
          {courses.map((item, index) => {
            return (
              <div
                className="col-lg-4 col-md-6 col-sm-12 custom-grid"
                key={index}
              >
                <div className="work-wrap">
                  <div className="work-icon">
                    <img src={item.icon} alt={item.icon} />
                  </div>
                  <div className="work-content">
                    <h2>{item.name}</h2>
                    <p>
                      Our master plans provide a comprehensive look at where an
                      organization is today.
                    </p>
                    {item.id ? (
                      <Link to={`/course-details/${item.id}`}>Read More</Link>
                    ) : (
                      <Link>Coming Soon</Link>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ServiceSection2;
