import React from "react";
import "./style.css";

const ServiceSection = (props) => {
  return (
    <div className={`wpo-service-area ${props.serviceclass}`}>
      <div className="container">
        <div className="col-12">
          <div className="section-title text-center">
            <h2>Services</h2>
          </div>
        </div>
        <div className="wpo-service-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="col-sm-12 wpo-service-wrap">
                <div className="col-sm-6 wpo-services-icon-wrap">
                  <div className="wpo-service-icon">
                    <i className="fi flaticon-idea"></i>
                  </div>
                </div>
                <div className="col-sm-6 wpo-service-text">
                  <h2>Mobile App Development</h2>
                  <p>
                    We build high quality mobile application for both ANDROID
                    and IOS platforms.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="col-sm-12 wpo-service-wrap">
                <div className="col-md-6 wpo-services-icon-wrap">
                  <div className="wpo-service-icon-2">
                    <i className="fi flaticon-files-and-folders"></i>
                  </div>
                </div>
                <div className="col-md-6 wpo-service-text">
                  <h2>Website Development</h2>
                  <p>
                    We create customised Websites according to your requiremets
                    for your business.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="col-sm-12 wpo-service-wrap">
                <div className="col-md-6 wpo-services-icon-wrap">
                  <div className="wpo-service-icon-3">
                    <i className="fi flaticon-artist"></i>
                  </div>
                </div>
                <div className="col-md-6 wpo-service-text">
                  <h2>Backend Development</h2>
                  <p>
                    We create secure and fast backend for your mobile and web
                    app.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="col-sm-12 wpo-service-wrap">
                <div className="col-md-6 wpo-services-icon-wrap">
                  <div className="wpo-service-icon-4">
                    <i className="fi flaticon-man"></i>
                  </div>
                </div>
                <div className="col-md-6 wpo-service-text">
                  <h2>SEO Services</h2>
                  <p>
                    We have best marketing team to promote your product all over
                    the world.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;
