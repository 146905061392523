import React, { Fragment } from "react";
import PageTitle from "../../components/PageTitle";
import Navbar3 from "../../components/Navbar3";
import Hero3 from "../../components/Hero3";
import ServiceSection from "../../components/ServiceSection";
// import PorfolioSection2 from "../../components/PorfolioSection2";
import ContactArea from "../../components/ContactArea";
import Footer from "../../components/Footer";
import Testimonial from "../../components/Testimonial2";
import AboutFeatures from "../../components/AboutFeature";
import FtLogo from "../../images/logo2.png";

const HomePage3 = () => {
  return (
    <Fragment>
      <PageTitle title="Seven Solver" />
      <Navbar3 />
      <Hero3 />
      <AboutFeatures />
      <ServiceSection serviceclass={"wpo-service-area-3 section-padding"} />
      {/* <PorfolioSection2 /> */}
      <Testimonial testclass={"wpo-testimonial-area-3"} />
      <ContactArea contactclass={"wpo-contact-area3"} />
      <Footer Ftclass={"wpo-footer-area3"} ftLogo={FtLogo} />
    </Fragment>
  );
};
export default HomePage3;
