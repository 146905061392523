import React, { Fragment } from "react";
import PageTitle from "../../components/PageTitle";
import Navbar5 from "../../components/Navbar5";
import Breadcrumbs from "../../components/Breadcrumbs";
import Footer from "../../components/Footer";
import ErroeSection from "../../components/ErroeSection";
import FtLogo from "../../images/logo2.png";

const ErroPage = () => {
  return (
    <Fragment>
      <PageTitle title="404 Page Not Found" />
      <Navbar5 />
      <Breadcrumbs pageTitle={"404 Error"} pageSub={"404 Not Found"} />
      <ErroeSection />
      <Footer Ftclass={"wpo-footer-area3"} ftLogo={FtLogo} />
    </Fragment>
  );
};
export default ErroPage;
