import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

const AboutFeatures = (props) => {
  return (
    <div className="about-feature-area">
      <div className="container">
        <div className="about-features-wrap">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-feature-left">
                <h2>We create the best product what you are looking for.</h2>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-feature-right">
                <p>
                  Here in the @seven solver, we give you the best quality
                  product with maintenance feature. We are providing many
                  solutions for Startups to Entrepreneurs level to help them to
                  enhance the online reach for the business.
                </p>
                <Link to="/about" className="theme-btn-s2">
                  More About
                </Link>
              </div>
            </div>
          </div>
          <div className="radius-ball"></div>
        </div>
      </div>
    </div>
  );
};

export default AboutFeatures;
