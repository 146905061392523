import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./style.css";

class TestSlider2 extends Component {
  render(props) {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      margin: 10,
      loop: true,
    };
    return (
      <div
        className={`wpo-testimonial-area wpo-testimonial-area-2 section-padding ${this.props.testclass}`}
      >
        <div className="container">
          <div className="col-12">
            <div className="section-title text-center">
              <h2>Testimonial</h2>
            </div>
          </div>
          <div className="wpo-testimonial-main">
            <Slider {...settings}>
              <div className="wpo-testimonial-wrap">
                <div className="wpo-testimonial-item">
                  <div className="wpo-testimonial-content">
                    <p>
                      Seven Solver Professionals were quick, courteous and very
                      helpful. They helped me in building my mobile app
                      according to my requirements. I was worried it wouldn't be
                      done in time for my launching date, but they finished the
                      job with time to spare!
                    </p>
                    <div className="wpo-testimonial-item2">
                      <div className="wpo-testimonial-thumb">
                        <h4>Mark Davis</h4>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpo-testimonial-wrap">
                <div className="wpo-testimonial-item">
                  <div className="wpo-testimonial-content">
                    <p>
                      Seven Solver Professionals were quick, courteous and very
                      helpful. They helped me in building my mobile app
                      according to my requirements. I was worried it wouldn't be
                      done in time for my launching date, but they finished the
                      job with time to spare!
                    </p>
                    <div className="wpo-testimonial-item2">
                      <div className="wpo-testimonial-thumb">
                        <h4>Mark Davis</h4>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpo-testimonial-wrap">
                <div className="wpo-testimonial-item">
                  <div className="wpo-testimonial-content">
                    <p>
                      Seven Solver Professionals were quick, courteous and very
                      helpful. They helped me in building my mobile app
                      according to my requirements. I was worried it wouldn't be
                      done in time for my launching date, but they finished the
                      job with time to spare!
                    </p>
                    <div className="wpo-testimonial-item2">
                      <div className="wpo-testimonial-thumb">
                        <h4>Mark Davis</h4>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

export default TestSlider2;
