import React from "react";
import ContactArea from "../ContactArea";

const ContactPageSec = () => {
  return (
    <section className="wpo-contact-page">
      <div className="wpo-wpo-contact-form-map">
        <div className="container">
          <div className="row">
            <ContactArea contactclass={"wpo-contact-area3"} />
            <div className="co-lg-10 offset-lg-1 col-12">
              <div className="contact-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3457.461379580881!2d77.54980261451776!3d29.93740488192134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390eeaad0cbf9973%3A0x39c76e5f442b9baa!2sVinod%20Vihar%20Field!5e0!3m2!1sen!2sin!4v1619382022348!5m2!1sen!2sin"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactPageSec;
