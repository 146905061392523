import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./style.css";

import proj1 from "../../images/project/app_develop.png";
import proj2 from "../../images/project/web_develop.png";
import proj3 from "../../images/project/backend_develop.png";
import proj4 from "../../images/project/seo_service.png";

const ServiceSingle = (props) => {
  const [services, setServices] = useState([
    {
      id: 1,
      image: proj1,
      title: "Mobile APP Development",
      description: `<style type="text/css">
      p.p1 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      p.p2 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57;
          min-height: 14.0px
      }
  
      p.p3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      p.p4 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a;
          min-height: 15.0px
      }
  
      p.p5 {
          margin: 0.0px 0.0px 0.0px 36.0px;
          color: #0e101a;
          min-height: 15.0px
      }
  
      p.p6 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      p.p7 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57;
          min-height: 18.0px
      }
  
      li.li3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      span.s1 {
          color: #fe3e57
      }
  
      ul.ul1 {
          list-style-type: none
      }
  </style>
  <p class="p3">Do you need a <strong>mobile application</strong> valid for any operating system that meets the needs of your business? Take the opportunity to connect with us, so you will work with more productively or sell more through hiring our mobile development service.</p>
  <p class="p4"></p>
  <p class="p3">Our company provides a full cycle of <strong>mobile application development</strong> from the stage of business analysis to release, publication, and even support. Here what you will get when hiring us:</p>
  <p class="p4"></p>
  <ul class="ul1">
      <li class="li3">Mobile testing</li>
      <li class="li3">Internal integrations</li>
      <li class="li3">Business analysis</li>
      <li class="li3">UI / UX design</li>
      <li class="li3">Development of cross-platform mobile applications</li>
      <li class="li3">Post to App Store &amp; Google Play.</li>
  </ul>
  <p class="p5"></p>
  <p class="p3">At <span class="s1">Seven Solver,&nbsp;</span>we offer you <strong>professional mobility solutions</strong> that will make you get the most out of your business. Our experienced <strong>mobile development</strong> team is ready to start working on your requirements.</p>
  <p class="p4"></p>
  <p class="p6"><strong>Get eco-friendly and custom-made mobile development solution:</strong></p>
  <p class="p3">We use the latest technologies to build a <strong>tailor-made mobile application</strong> that fulfills your needs. We have extensive experience in the field of web development, as well as a work team with a large number of projects carried out. At <span class="s1"><em>Seven Solver</em></span>, we offer three solutions for you.</p>
  <p class="p4"></p>
  <ul class="ul1">
      <li class="li3"><span class="s1">Android application:&nbsp;</span>We develop <strong>eco-friendly mobile applications</strong> with publication on Google Play. Our team is here to solve issues in the path of developing an app on Android mobile devices.</li>
  </ul>
  <p class="p4"></p>
  <ul class="ul1">
      <li class="li3"><span class="s1">IOS application:&nbsp;</span>Creation of applications for Apple devices while focusing frontend, backend, and UX / UI design. Also, we are solving complex problems in the path of development.</li>
  </ul>
  <p class="p4"></p>
  <ul class="ul1">
      <li class="li3"><span class="s1">Cross-platform application:&nbsp;</span>We offer a one-stop solution for <strong>app development for Android</strong> <strong>and iOS</strong>. Indeed, creation on a hybrid platform will save time and budget.</li>
  </ul>
  <p class="p4"></p>
  <p class="p4"></p>
  <p class="p6"><strong>Let&rsquo;s take the advantages of our service:</strong></p>
  <p class="p7"></p>
  <ul class="ul1">
      <li class="li3"><span class="s1"><strong>Better contact with customers:&nbsp;</strong></span><strong>Mobile application development</strong> is more interactive and can be integrated with device functions.</li>
  </ul>
  <p class="p4"></p>
  <ul class="ul1">
      <li class="li3"><span class="s1">Eco-friendly experience</span>: A user will access such great platforms as App Store, Google Play, and Microsoft Store through a better mobile application.</li>
  </ul>
  <p class="p4"></p>
  <ul class="ul1">
      <li class="li3"><span class="s1"><strong>Bring customer loyalty:&nbsp;</strong></span>Unlike browser bookmarks, apps always stay on the front of the home screen; that&rsquo;s why we create more visibility for your brand.</li>
  </ul>
  <p class="p4"></p>
  <ul class="ul1">
      <li class="li3"><span class="s1"><strong>Enhance customer experience:&nbsp;</strong></span>Mobile apps offer faster one-step access by tapping the app icon without opening a web browser.</li>
  </ul>
  <p class="p4"></p>
  <p class="p3">We also offer solutions to improve your business, thanks to technical knowledge and data analytics. Let&rsquo;s connect with us; we will contact you to estimate the cost of creating an application.</p>
  <p class="p4"></p>
  <p class="p4"></p>
  <p class="p4"></p>`,
    },
    {
      id: 2,
      image: proj2,
      title: "Website Development",
      description: `<style type="text/css">
      p.p1 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      p.p2 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57;
          min-height: 14.0px
      }
  
      p.p3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      p.p4 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a;
          min-height: 15.0px
      }
  
      p.p5 {
          margin: 0.0px 0.0px 0.0px 36.0px;
          color: #0e101a;
          min-height: 15.0px
      }
  
      p.p6 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      p.p7 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57;
          min-height: 18.0px
      }
  
      li.li3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      span.s1 {
          color: #fe3e57
      }
  
      ul.ul1 {
          list-style-type: none
      }
  </style>
  <style type="text/css">
      p.p1 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      p.p2 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57;
          min-height: 14.0px
      }
  
      p.p3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      p.p4 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a;
          min-height: 15.0px
      }
  
      p.p5 {
          margin: 0.0px 0.0px 0.0px 36.0px;
          color: #0e101a;
          min-height: 15.0px
      }
  
      p.p6 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      p.p7 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57;
          min-height: 18.0px
      }
  
      li.li3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      span.s1 {
          color: #fe3e57
      }
  
      ul.ul1 {
          list-style-type: none
      }
  </style>
  <style type="text/css">
      p.p1 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      p.p2 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57;
          min-height: 14.0px
      }
  
      p.p3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      p.p4 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a;
          min-height: 15.0px
      }
  
      li.li3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      li.li5 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      span.s1 {
          color: #fe3e57
      }
  
      ul.ul1 {
          list-style-type: none
      }
  </style>
  <p class="p3"><span class="s1"><em>Seven Solver</em>&nbsp;</span>has a diverse team of experts in <strong>web development</strong>, web design, user experience, and usability for large and small business websites. Our web developer professional knows how to successfully integrate with other team members to achieve the objective set because we know Web programming projects are usually complex.</p>
  <p class="p4"></p>
  <p class="p3">We provide premium interactive quality web development services to our overseas clients and agencies. <span class="s1"><em>Seven Solver</em>&nbsp;</span><strong>web development solutions</strong> are a combination of innovative solutions with creativity. Our website development technologies include:<span class="Apple-converted-space">&nbsp;</span></p>
  <ul class="ul1">
      <li class="li3">React.js<span class="Apple-converted-space">&nbsp;</span></li>
      <li class="li3">Angular<span class="Apple-converted-space">&nbsp;</span></li>
      <li class="li3">Vue.js<span class="Apple-converted-space">&nbsp;</span></li>
  </ul>
  <p class="p4"></p>
  <p class="p3">However, our web design delivers meaningful, measurable, and valuable results to our clients. We have a large portfolio of web design projects and numerous satisfied clients because we focus on quality and user-friendly results</p>
  <p class="p1"><strong>We manage &amp; design your dream website<span class="Apple-converted-space">&nbsp;</span></strong></p>
  <p class="p2"></p>
  <p class="p3">Seven Solver is a full-time custom web development company that offers qualitative and effective services that fulfill the demand of their clients. Custom web development using high programming standards, based on free software such as Bootstrap, HTML, JS.</p>
  <p class="p4"></p>
  <p class="p3">Our expert web developer team take care of the entire <strong>web development process</strong> for your business, from prototyping to launching a business website. Here, what you will get from us:</p>
  <p class="p4"></p>
  <p class="p4"></p>
  <ul class="ul1">
      <li class="li5"><strong>Web layout</strong></li>
  </ul>
  <p class="p3">Get a lightweight, user-centric, scalable, and easy to maintain user interface thanks to the component approach that follows typography, modular spaces, grids. We are your Frontend development team to carry out those projects whose design complexity requires extra help.</p>
  <p class="p3">We make the perfect website design by transforming the design into HTML5, CSS, and Javascript code.</p>
  <p class="p4"></p>
  <ul class="ul1">
      <li class="li5"><strong>UX / UI design solutions</strong></li>
  </ul>
  <p class="p3">We go beyond the visual aspects of your product and understand how the audience engages with design to explore the high-quality experience. We create and manage the website&apos;s user experience and provide a balanced web environment to our users. Our team of developers is here to increase your user experience goals significantly.</p>
  <p class="p4"></p>
  <p class="p3">We deliver <strong>digital-tailored web development</strong> solutions that fit your exact business needs. Our <strong>web development solutions</strong> boost your digital image and handle all aspects of a web project, post-launch monitoring, starting with strategic planning or even <strong>web maintenance.</strong> By hiring us, you will deliver meaningful results that are measurable and valuable to our customers.</p>
  <p class="p4"></p>
  <p class="p4"></p>`,
    },
    {
      id: 3,
      image: proj3,
      title: "Backend Developement",
      description: `<style type="text/css">
      p.p1 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      p.p2 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57;
          min-height: 14.0px
      }
  
      p.p3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      p.p4 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a;
          min-height: 15.0px
      }
  
      p.p5 {
          margin: 0.0px 0.0px 0.0px 36.0px;
          color: #0e101a;
          min-height: 15.0px
      }
  
      p.p6 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      p.p7 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57;
          min-height: 18.0px
      }
  
      li.li3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      span.s1 {
          color: #fe3e57
      }
  
      ul.ul1 {
          list-style-type: none
      }
  </style>
  <style type="text/css">
      p.p1 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      p.p2 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57;
          min-height: 14.0px
      }
  
      p.p3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      p.p4 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a;
          min-height: 15.0px
      }
  
      p.p5 {
          margin: 0.0px 0.0px 0.0px 36.0px;
          color: #0e101a;
          min-height: 15.0px
      }
  
      p.p6 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      p.p7 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57;
          min-height: 18.0px
      }
  
      li.li3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      span.s1 {
          color: #fe3e57
      }
  
      ul.ul1 {
          list-style-type: none
      }
  </style>
  <style type="text/css">
      p.p1 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      p.p2 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57;
          min-height: 14.0px
      }
  
      p.p3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      p.p4 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a;
          min-height: 15.0px
      }
  
      li.li3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      li.li5 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      span.s1 {
          color: #fe3e57
      }
  
      ul.ul1 {
          list-style-type: none
      }
  </style>
  <style type="text/css">
      p.p1 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          min-height: 14.0px
      }
  
      p.p2 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      p.p3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57;
          min-height: 18.0px
      }
  
      p.p4 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      p.p5 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a;
          min-height: 15.0px
      }
  
      p.p6 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      p.p7 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57;
          min-height: 15.0px
      }
  
      li.li4 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      span.s1 {
          color: #fe3e57
      }
  
      ul.ul1 {
          list-style-type: none
      }
  </style>
  <style type="text/css">
      p.p1 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      p.p2 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57;
          min-height: 16.0px
      }
  
      p.p3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      p.p4 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a;
          min-height: 15.0px
      }
  
      p.p5 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      li.li3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      span.s1 {
          color: #fe3e57
      }
  
      ul.ul1 {
          list-style-type: none
      }
  </style>
  <p class="p3"><span class="s1"><em>Seven Solver</em>&nbsp;</span>offers high-quality <strong>backend services</strong> for startups, enterprises, and business owners. However, the correct architecture and usability of the backend will provide you the convenience to use your site. This is the point where you need our professional <strong>backend service.</strong></p>
  <p class="p4"></p>
  <p class="p3">The backend is the most difficult component in developing the core and functionality for creating the administrative panel. At <span class="s1"><em>Seven Solver</em></span>, you will connect with professionals with enough knowledge to handle back-end difficulties.</p>
  <p class="p4"></p>
  <p class="p5"><strong>Backend tailor-made development service:</strong></p>
  <p class="p3">Our developers handle all type of difficult backend development task. We also give guarantee that we provide the <strong>high quality backend service</strong> because we have a team of qualified specialists that have enough experience in development. Here are some benefits to choose our tailor-made backend service:</p>
  <p class="p4"></p>
  <ul class="ul1">
      <li class="li3">Fast CMS &amp; high conversion rate</li>
      <li class="li3">Detailed analysis of your project.</li>
      <li class="li3">Smart structure for your site.</li>
      <li class="li3">Development of professional technical specifications.</li>
      <li class="li3">Unique solutions for the backend.</li>
      <li class="li3">Internal backend optimization.</li>
  </ul>
  <p class="p4"></p>
  <p class="p4"></p>
  <p class="p5"><strong>We handle all typical task of the Backend:</strong></p>
  <p class="p3">Our specialist deals with the software and administrative part of a web application, the internal content of the system, server technologies such as architecture, database, and program logic. We develop qualitative backend solutions for highly loaded and technically complex websites.</p>
  <p class="p4"></p>
  <p class="p3">At <span class="s1"><em>Seven Solver,</em>&nbsp;</span>we manipulate<strong>&nbsp;backend</strong> for custom CRM &amp; ERP systems, eCommerce, media, and financial services. Our team is ready to start developing the server side at any stage: from consulting and developing technical specifications to migrating to the cloud. We can provide high-quality service because our backend developers use all known modern technologies.</p>
  <p class="p4"></p>
  <p class="p5"><strong>Backend for mobile applications</strong></p>
  <p class="p3">If you plan to process user data, accept payments, store a catalog of goods, then our team will help you create a reliable backend for an iOS or Android application. First, our <strong>professional backend team&nbsp;</strong>will analyze your idea and explain all the technical details to understand how it works and what it gives, and then we will develop the server-side for you.</p>
  <p class="p4"></p>
  <p class="p3">We love challenging tasks and are ready to demonstrate our expertise in solving them. Discuss your project with us today</p>
  <p class="p4"></p>
  <p class="p4"></p>`,
    },
    {
      id: 4,
      image: proj4,
      title: "SEO Services",
      description: `<style type="text/css">
      p.p1 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      p.p2 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57;
          min-height: 14.0px
      }
  
      p.p3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      p.p4 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a;
          min-height: 15.0px
      }
  
      p.p5 {
          margin: 0.0px 0.0px 0.0px 36.0px;
          color: #0e101a;
          min-height: 15.0px
      }
  
      p.p6 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      p.p7 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57;
          min-height: 18.0px
      }
  
      li.li3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      span.s1 {
          color: #fe3e57
      }
  
      ul.ul1 {
          list-style-type: none
      }
  </style>
  <style type="text/css">
      p.p1 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      p.p2 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57;
          min-height: 14.0px
      }
  
      p.p3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      p.p4 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a;
          min-height: 15.0px
      }
  
      p.p5 {
          margin: 0.0px 0.0px 0.0px 36.0px;
          color: #0e101a;
          min-height: 15.0px
      }
  
      p.p6 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      p.p7 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57;
          min-height: 18.0px
      }
  
      li.li3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      span.s1 {
          color: #fe3e57
      }
  
      ul.ul1 {
          list-style-type: none
      }
  </style>
  <style type="text/css">
      p.p1 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      p.p2 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57;
          min-height: 14.0px
      }
  
      p.p3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      p.p4 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a;
          min-height: 15.0px
      }
  
      li.li3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      li.li5 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      span.s1 {
          color: #fe3e57
      }
  
      ul.ul1 {
          list-style-type: none
      }
  </style>
  <style type="text/css">
      p.p1 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          
          min-height: 14.0px
      }
  
      p.p2 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      p.p3 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57;
          min-height: 18.0px
      }
  
      p.p4 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      p.p5 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a;
          min-height: 15.0px
      }
  
      p.p6 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57
      }
  
      p.p7 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #fe3e57;
          min-height: 15.0px
      }
  
      li.li4 {
          margin: 0.0px 0.0px 0.0px 0.0px;
          color: #0e101a
      }
  
      span.s1 {
          color: #fe3e57
      }
  
      ul.ul1 {
          list-style-type: none
      }
  </style>
  <p class="p4"><span class="s1"><em>Seven Solver</em>&nbsp;</span>is highly specialized agency to offer the best <strong>SEO services</strong> to give you strong visibility in search results. Our SEO strategies enhance traffic and exposure to your customers. At <span class="s1"><em>Seven Solver</em></span>, we will help you to identify opportunities for transformative growth that include search queries.</p>
  <p class="p5"></p>
  <p class="p4">Our team of professional <strong>Search Engine Optimizers</strong> is here to rank your website at the top of Google Search Engine. The top of SERP means the top of the mind. But how do you get there? We build local search strategies that integrate seamlessly with content strategies, focus equally on users and algorithms, and allow you to keep track of KPIs.</p>
  <p class="p5"></p>
  <p class="p6"><strong>Our main focus:</strong></p>
  <p class="p7"></p>
  <ul class="ul1">
      <li class="li4">Identify the business objectives of the website or digital presence</li>
      <li class="li4">Create referral keywords</li>
      <li class="li4">Generate a profile and strategy report through a backlink</li>
      <li class="li4">Support the implementation of improvements on the page</li>
      <li class="li4">Develop a deep on-page SEO audit</li>
      <li class="li4">Track and report on achievements and opportunities</li>
  </ul>
  <p class="p5"></p>
  <p class="p6"><strong>We Optimize Your Website &amp; Rank on Google:</strong></p>
  <p class="p4">At <span class="s1"><em>Seven Solver,</em>&nbsp;</span>we use different strategies to achieve the target of optimization of your website so that it&rsquo;s easy to get an attractive website for users, and thus place it in a higher position in different Internet searches. Our search engine optimization services include:</p>
  <p class="p5"></p>
  <ul class="ul1">
      <li class="li4"><span class="s1"><strong>Link Building:</strong>&nbsp;</span>The most effective way to get <strong>SEO ranking</strong> and authority built into your SEO strategy. Through link building, we enhance the visibility of your website to get more audience and rank higher for more traffic &amp; leads.</li>
  </ul>
  <p class="p5"></p>
  <ul class="ul1">
      <li class="li4"><span class="s1"><strong>SEO On-Page:</strong>&nbsp;</span>We optimize your content to make it attractive to users, potential customers, and search engines. We put relevant keywords in your content while uploading it on the website to improve your ranking.</li>
  </ul>
  <p class="p5"></p>
  <ul class="ul1">
      <li class="li4"><span class="s1"><strong>Link Building:</strong>&nbsp;</span>The most effective way to get <strong>SEO ranking</strong> because we have the authority to build your digital image through our better SEO service techniques. We build your outbound link to enhance your website&apos;s popularity and rank higher to get more leads.</li>
  </ul>
  <p class="p5"></p>
  <ul class="ul1">
      <li class="li4"><span class="s1"><strong>Keyword audit:</strong>&nbsp;</span>The necessary first step of any successful SEO strategy is understanding the keywords your target audience uses when they are searching for your products or services.</li>
  </ul>
  <p class="p5"></p>
  <p class="p4">Let&rsquo;s track your ROI and ranking through organic traffic. Our main aim is to build a digital image of your website and rank on the first page of Google.</p>
  <p class="p5"></p>
  <p class="p4"></p>`,
    },
  ]);
  const [selectService, setSelectService] = useState({});
  const [selectId, setSelectId] = useState(1);

  const handleService = (_id) => {
    const filter = services.filter((item) => item.id === selectId);
    console.log(filter);
    setSelectService(filter[0]);
  };

  useEffect(() => {
    handleService();
  }, [selectId]);

  return (
    <div className="wpo-project-details-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="wpo-service-sidebar">
              <div className="widget all-wpo-services">
                <h3>All Services</h3>
                <ul>
                  <li className="current">
                    <button
                      className="btn btn-light"
                      onClick={() => setSelectId(1)}
                    >
                      Mobile APP Development
                    </button>
                  </li>
                  <li>
                    <button
                      className="btn btn-light"
                      onClick={() => setSelectId(2)}
                    >
                      Website Development
                    </button>
                  </li>
                  <li>
                    <button
                      className="btn btn-light"
                      onClick={() => setSelectId(3)}
                    >
                      Backend Developement
                    </button>
                  </li>
                  <li>
                    <button
                      className="btn btn-light"
                      onClick={() => setSelectId(4)}
                    >
                      SEO Services
                    </button>
                  </li>
                </ul>
              </div>
              <div className="widget contact-widget">
                <div>
                  <h5 className="text-light">Request a Call Back</h5>
                  <h5 className="text-light">+91 97567-71250</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="wpo-minimals-wrap">
              <div className="minimals-img">
                <img src={selectService?.image} alt="" loading="lazy" />
              </div>
            </div>
            <div className="wpo-p-details-section">
              <h4>{selectService?.title}</h4>
              <div
                dangerouslySetInnerHTML={{ __html: selectService?.description }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSingle;
