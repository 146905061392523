import React, { Component } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import "./style.css";

// const Scrollbar = () => {

//     return(
//         <div className="col-lg-12">
//             <div className="header-menu">
//                 <ul className="smothscroll">
//                     <li><AnchorLink href='#scrool'><i className="ti-arrow-up"></i></AnchorLink></li>
//                 </ul>
//             </div>
//         </div>

//     )
// }

// export default Scrollbar;

class Scrollbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_visible: false,
    };
  }
  componentDidMount() {
    var scrollComponent = this;
    document.addEventListener("scroll", function (e) {
      scrollComponent.toggleVisibility();
    });
  }
  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true,
      });
    } else {
      this.setState({
        is_visible: false,
      });
    }
  }
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    const { is_visible } = this.state;
    return (
      <div className="col-lg-12">
        {is_visible && (
          <div className="header-menu">
            <ul className="smothscroll">
              <li>
                <AnchorLink href="#scrool">
                  <i className="ti-arrow-up"></i>
                </AnchorLink>
              </li>
            </ul>
          </div>
        )}
        <div className={!is_visible ? "skype-icon s_bottom" : "skype-icon"}>
          <a href="" target="_blank">
            <i className="fa fa-skype"></i>
          </a>
        </div>
        <div className={!is_visible ? "whtsapp-icon w_bottom" : "whtsapp-icon"}>
          <a href="https://wa.me/+919756771250" target="_blank">
            <i className="fa fa-whatsapp"></i>
          </a>
        </div>
      </div>
    );
  }
}

export default Scrollbar;
