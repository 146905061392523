import React, { Fragment } from "react";
import PageTitle from "../../components/PageTitle";
import Navbar5 from "../../components/Navbar5";
import Breadcrumbs from "../../components/Breadcrumbs";
import Footer from "../../components/Footer";
import Portfolio from "../../components/PorfolioSection3";
import FtLogo from "../../images/logo2.png";

const PorfolioPage = () => {
  return (
    <Fragment>
      <PageTitle title="Portfolio | Seven Solver" />
      <Navbar5 />
      <Breadcrumbs pageTitle={"Portfolio"} pageSub={"Portfolio"} />
      <Portfolio />
      <Footer Ftclass={"wpo-footer-area3"} ftLogo={FtLogo} />
    </Fragment>
  );
};
export default PorfolioPage;
