import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

const config = {
  apiKey: "AIzaSyC_G22b75HDI8tY5bUNluFM2-b6Hqr1KiM",
  authDomain: "sevensolver-584e4.firebaseapp.com",
  databaseURL: "https://sevensolver-584e4-default-rtdb.firebaseio.com",
  projectId: "sevensolver-584e4",
  storageBucket: "sevensolver-584e4.appspot.com",
  messagingSenderId: "449622971657",
  appId: "1:449622971657:web:0613fc9bdb6f3158c46fd8",
  measurementId: "G-70GJCXBXSH",
};
firebase.initializeApp(config);

export default firebase;
