import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import about from "../../images/about/about.png";

const AboutArea3 = (props) => {
  return (
    <div className="wpo-about-area-3 section-padding">
      <div className="wpo-about-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="about-s2-img">
                <img src={about} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="wpo-about-text">
                <p>
                  SevenSolver is a complete service IT company with a wide range
                  of services. We provide the best agile, end-to-end and good
                  quality product to you. We add efficiency with full of focus
                  in our development process and increase visibility of your
                  online product to the world.
                </p>
                <p>
                  We use robust coding way, complex effective strategies and
                  strong project management skills. Today we’re proud to have a
                  great skilled team of developers who always ready to solve
                  your IT problems and reach out to your business needs.
                </p>
                <p>
                  Connect with us today and get you free online consult for your
                  business.
                </p>
                <ul>
                  <li>High Quality Service</li>
                  <li>Best Price in Marketplace.</li>
                  <li>Unique Design and Development.</li>
                </ul>
                {/* <Link to="/" className="theme-btn-s2">
                  Download CV
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutArea3;
